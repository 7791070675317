import { Slide } from "react-slideshow-image";
import { useParams, Navigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import classes from "./index.module.css";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { useState } from "react";

export const arr = [
  {
    name: "Architectural elevations",
    length: 10,
    folder: "ae",
    ext: "jpg",
  },
  {
    name: "Clinic",
    length: 6,
    folder: "clicnic",
    ext: "jpg",
  },
  {
    name: "Commercial work",
    length: 8,
    folder: "cm",
    ext: "jpg",
  },
  {
    name: "Diagnostic center",
    length: 2,
    folder: "dc",
    ext: "jpg",
  },
  {
    name: "Farm house",
    length: 6,
    folder: "fh",
    ext: "jpg",
  },
  {
    name: "Ivory room saloon",
    length: 13,
    folder: "irs",
    ext: "jpg",
  },
  {
    name: "Kids room design",
    length: 16,
    folder: "kds",
    ext: "jpg",
  },

  {
    name: "Real estate office",
    length: 4,
    folder: "Reo",
    ext: "jpg",
  },

  {
    name: "Toilet designs",
    length: 7,
    folder: "td",
    ext: "jpg",
  },
  {
    name: "Terrace design",
    length: 5,
    folder: "terrd",
    ext: "jpg",
  },

  {
    name: "Wupi trampoline park",
    length: 7,
    folder: "wtp",
    ext: "jpg",
  },
];

const Slider = () => {
  const [back, setBack] = useState(null);
  const { id } = useParams();
  const el = arr.find((data) => data.folder === id);

  if (!el) {
    return <Navigate to="/" />;
  }

  const ss = [];
  for (let i = 0; i < el.length; i++) {
    ss.push(
      <div className="each-slide-effect">
        <img
          className={classes.responsive_image}
          src={require(`../../assets/slider/${el.folder}/${i + 1}.${el.ext}`)}
        />
      </div>
    );
  }

  return (
    <div className={classes.slider}>
      {back ? back : null}
      <Slide
        duration={2000}
        transitionDuration={1000}
        prevArrow={
          <AiOutlineArrowLeft
            style={{ zIndex: 10, fontSize: "3vh", color: "white" }}
          />
        }
        nextArrow={
          <AiOutlineArrowRight
            style={{ zIndex: 10, fontSize: "3vh", color: "white" }}
          />
        }
      >
        {ss}
      </Slide>
      <div>
        <p className={classes.warn}>
          Please view in desktop mode for a better experience
        </p>
        <p
          style={{ textAlign: "right", paddingBottom: "2vh" }}
          className={classes.hover}
          onClick={() => {
            setBack(<Navigate to="/projects"></Navigate>);
          }}
        >
          Back to Projects
        </p>
      </div>
    </div>
  );
};

export default Slider;
