import logo from "./logo.svg";
import "./App.css";
import Home from "./screens/home";
import About from "./screens/about";
import Contact from "./screens/contact";
import Slider from "./screens/slider";
import { Route, Routes } from "react-router-dom";
import Projects from "./screens/Projects";
import Header from "./utils/Header";
import Footer from "./utils/Footer";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/slides/:id" element={<Slider />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
