import video from "../../video/interior.mp4";
import classes from "./index.module.css";
import arch from "../../assets/cards/arch.jpg";
import fur from "../../assets/cards/furniture.jpg";
import gra from "../../assets/cards/graphics.png";
import int from "../../assets/cards/interiors.jpg";
import va from "../../assets/slider/terrd/1.jpg";
import mv from "../../assets/slider/fh/1.jpg";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const cards = [
  {
    text: 'Starting with intensive research, we understand and analyze each project in its Environmental, Cultural and Historical Context to create "Unique Architectures" without compromising on quality and without breaking your budget.',
    img: arch,
  },
  {
    text: 'Guided by our passion, we pursue to offer interior services for Residential, Commercial, Institutional, Hotel and Medical projects. We give you more "Decent and Elegant Interiors" from which you cannot take your eyes off.',
    img: fur,
  },
  {
    text: 'We offer highly personalized services tailored to your needs. We engage with clients directly, making them a part of the "Designing the Graphics" process and to make sure you feel all the colours.',
    img: gra,
  },
  {
    text: 'Inspired by the legacy of artistry in traditional wood carvings we use technology and tradition to bring out the distinctive qualities of a "Well-Crafted Furniture" which suits your home and personality.',
    img: int,
  },
];

const projects = [
  {
    img: va,
    title: "Terrace Design",
    cat: "Outdoor",
    ls: "terrd",
  },
  {
    img: mv,
    title: "Farm House",
    cat: "Interiors",
    ls: "fh",
  },
];

const Home = () => {
  const [show, setShow] = useState(false);
  const [link, setLink] = useState(null);

  const showOverlay = () => {
    setShow(true);
  };

  const hideOverlay = () => {
    setShow(false);
  };

  return (
    <div>
      {link ? link : null}
      <video
        id="myvideo"
        className={classes.video}
        playsinline="playsinline"
        autoplay="autoplay"
        muted="muted"
      >
        <source class="active" src={video} type="video/mp4" />
      </video>
      <div className={classes.container}>
        <div className={classes.heading}>
          <h6 className={classes.h6Text}>
            Dual Creators is a multi-disciplinary company engaged in providing
            Holistic and Environmentally Responsive Architecture and Interior
            Design Solutions
          </h6>
        </div>
        <div className={classes.cards}>
          <h6
            className={classes.h6Text}
            style={{ margin: 0, marginBottom: "2vh" }}
          >
            Our Services
          </h6>
          <div className={classes.cardManager}>
            {cards.map((card, index) => (
              <div
                className={classes.image_container}
                onMouseOver={showOverlay}
                onMouseLeave={hideOverlay}
              >
                <img src={card.img} alt="Image" />
                {show && (
                  <div className={classes.overlay}>
                    <p>{card.text}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.projects}>
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -10,
          }}
          id="particles-js"
        ></div>
        <div className={classes.head}>
          <h2>OUR RECENT PROJECTS</h2>
          <hr className={classes.hr} />
          <button
            className={classes.button}
            onClick={() => {
              setLink(<Navigate to="/projects" />);
            }}
          >
            More Projects
          </button>
        </div>
        <div className={classes.projects_card}>
          {projects.map((card) => {
            return (
              <div
                className={classes.recentCard}
                onClick={() => {
                  setLink(<Navigate to={"/slides/" + card.ls} />);
                }}
              >
                <div className={classes.hider}> </div>
                <img src={card.img} width={"100%"} />
                <h4>{card.title}</h4>
                <h6>{card.cat}</h6>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
