import abpic from "../../assets/au.jpg";
import aa1 from "../../assets/leadership/1.jpg";
import aa2 from "../../assets/leadership/2.jpg";
import a1 from "../../assets/slider/cm/1.jpg";
import a2 from "../../assets/slider/fh/1.jpg";
import a3 from "../../assets/slider/td/1.jpg";
import classes from "./index.module.css";
import { useState } from "react";
import { Navigate } from "react-router-dom";

const leaders = [
  { img: aa1, name: "Ar Rahul", post: "B.ARCH, MPLAN" },
  {
    img: aa2,
    name: "Ms. Priya",
    post: "BSC, MSC, Vastu Consultant",
  },
];

const projects = [
  {
    img: a1,
    name: "Commercial Work",
    cat: "INTERIORS",
    ls: "cw",
  },
  {
    img: a2,
    name: "Farm House",
    cat: "HOUSING",
    ls: "fm",
  },
  {
    img: a3,
    name: "Terrace Design",
    cat: "INTERIORS",
    ls: "td",
  },
];

const About = () => {
  const [link, setLink] = useState(null);
  return (
    <div className={classes.container}>
      {link ? link : null}
      <div className={classes.hero}>
        <img src={abpic} width={"100%"} />
      </div>
      <div className={classes.aboutUs}>
        <h2>About Us</h2>
        <div className={classes.about_cards}>
          <div className={classes.about_card}>
            <p className={classes.about_para}>
              We started our journey in Gurugram in 2019 and we have been
              expanding at a good pace since. We are a well-known service
              provider that is involved in rendering the Architectural Services
              which does includes residential, commercial, industrial, housing,
              and institutional projects. Besides we also provide Interior
              Designing Services. We serve a broad number of sectors and are
              well respected within community.We keep the international industry
              standard in mind and offer thebest services to our clients.
            </p>
          </div>
          <div className={classes.about_card}>
            <p className={classes.about_para}>
              Due to our quality proven services, we have attained a strong
              credibility in the market and have a good customer base. In order
              to meet utmost satisfaction, our services are available in the
              market at feasible prices. we have been growing non-stop which has
              allowed us to form unique tie ups in the market. We have a
              dedicated team which listens and analyzes all your needs in
              details and deliver the best solution tailored perfectly to your
              requirements.
            </p>
          </div>
          <div className={classes.about_card}>
            <h6 className={classes.about_heading}>CLIENT SATISFACTION</h6>
            <p className={classes.about_para}>
              Being a customer focused company, we are providing quality assured
              services. These services are supervised under the management of
              our keen professionals using state of the art technologies. We
              learn from the constant feedback from our clients to allow us to
              deliver better services to you.
            </p>
          </div>
        </div>
      </div>
      <div className={classes.leadership}>
        <h4>LEADERSHIP</h4>
        <div className={classes.leader_cards}>
          {leaders.map((leader) => {
            return (
              <div className={classes.card}>
                <img src={leader.img} />
                <h5>{leader.name}</h5>
                <p>{leader.post}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.showcase}>
        <h4>Showcase Projects</h4>
        <div className={classes.projects}>
          {projects.map((project) => {
            return (
              <div
                className={classes.projectCard}
                onClick={() => {
                  setLink(<Navigate to={"/slides/" + project.ls} />);
                }}
              >
                <img src={project.img} />
                <h4>{project.name}</h4>
                <h6>{project.cat}</h6>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
