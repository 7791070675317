import { Navigate } from "react-router-dom";
import { arr } from "../slider";
import classes from "./index.module.css";
import { useState } from "react";

const Projects = () => {
  const [link, setLink] = useState(null);
  return (
    <div className={classes.projects}>
      {link ? link : null}
      {arr.map((project) => {
        return (
          <div
            className={classes.projectCard}
            onClick={() =>
              setLink(<Navigate to={`/slides/${project.folder}`} />)
            }
          >
            <img
              src={require(`../../assets/slider/${project.folder}/1.${project.ext}`)}
            />
            <h4>{project.name}</h4>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
