import { useState } from "react";
import styles from "./index.module.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");

  const [message, setMessage] = useState("");
  return (
    <div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d112279.42346042016!2d77.050262!3d28.427343!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19d554037683%3A0x3accd9cea33f9441!2sDual%20Creators!5e0!3m2!1sen!2sin!4v1697829927576!5m2!1sen!2sin"
          width={"100%"}
          frameBorder={"0"}
          style={{ border: 0 }}
          className={styles.iframe}
        ></iframe>
      </div>
      <div className={styles.main}>
        <div className={styles.container}>
          <div style={{ marginBottom: "2vh" }}>
            <label>Name *</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div style={{ marginBottom: "2vh" }}>
            <label>Email *</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div style={{ marginBottom: "2vh" }}>
            <label>Contact No *</label>
            <input
              type="text"
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
              required
            />
          </div>
          <div style={{ marginBottom: "2vh" }}>
            <label>Message *</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button>SEND</button>
        </div>
        <div className={styles.branchContainer}>
          <div className={styles.branch}>
            <h5>Branch:</h5>
            <p>Tower b-345 spaze edge sohna road Gurugram haryana</p>
            <hr />
            <p>Contact Us: 09999277273</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
