import styles from "./Navbar.module.css";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light "
      style={{ background: "#343a40", color: "white" }}
    >
      <a className="navbar-brand" href="#">
        <img src={logo} width={"160px"} />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <span className="custom-toggler-lines"></span>
          <span className="custom-toggler-lines"></span>
          <span className="custom-toggler-lines"></span>
        </span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ">
          <li className="nav-item ">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item ">
            <Link className="nav-link" to="/about">
              About
            </Link>
          </li>
          <li className="nav-item ">
            <Link className="nav-link" to="/projects">
              Projects
            </Link>
          </li>
          <li className="nav-item ">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
