import logo from "../../assets/logo.png";
import classes from "./index.module.css";
import { AiFillYoutube, AiFillFacebook, AiFillInstagram } from "react-icons/ai";
const Footer = () => {
  return (
    <div className={classes.container}>
      <img src={logo} width={"200px"} />
      <div className={classes.text}>
        <p>
          Dual Creators brings together an experienced team of professionals
          passionate about leading-edge design, mindful of compliance, and
          committed to deadlines. We provide an intimate experience with close
          level of engagement and service, irrespective of project size.
        </p>
        <p>
          Your dreams and our vision combine to create delightful built
          environments.
        </p>
      </div>
      <div className={classes.mores}>
        <a href="https://www.youtube.com/channel/UC-CzTD0KpWtNAyHQFbCL3Mg">
          <AiFillYoutube fontSize={30} />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100064144142817">
          <AiFillFacebook fontSize={30} />
        </a>
        <a href="https://www.instagram.com/invites/contact/?i=174rkc1z8mj9m&utm_content=8y563ov">
          <AiFillInstagram fontSize={30} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
